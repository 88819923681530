.btn button {
    position: relative;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2;
    height: 100%;
    transition: all 200ms linear;
    border-radius: 5px;
    width: 100%;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    align-self: flex-start;
    border: none;
    cursor: pointer;
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, .25);
    outline: 0;
    text-transform: capitalize;
    background-color: #5227CC;
    color: #ffffff;
}
.detailpage-input {
  margin-top: 15px;
  margin-bottom: 15px;
}
.detailpage-input Input {
  border-radius: 8px;
}
.detailpage-input-Select {
  border-radius: 8px;
  width: 100%;
}
.CustomButtonstyle {
  text-align: center;
}
.site-page-header{
  position: fixed;
  width:100%;
}
.business_table{
  margin-left: 20px;
  margin-top: 50px;
}
.search_input {
  width: 260px;
  border-radius: 25px;
  height: 38px;
  float: right;
  margin-top: 0px;
}
.ant-table-content{
  margin-top: 24px;
  margin-left: -19px;
}
.search_input{
  color: darkred;
  text-decoration: none;
  background-color: transparent;
}

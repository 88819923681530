#header {
  position: fixed;
  width: 150px;
  height: 100%;
  top: 0;
  left: 0;
}
#header .pro-sidebar {
  height: 100vh;
  color: #013b8e !important; 
}
 .center-col {
     flex: 1;
     background: #aaa;
    overflow-y: auto;
 }
.center-col::-webkit-scrollbar {
  width: 9px;
}
.center-col::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
#header .pro-sidebar-content{
  margin-left: -17px;
}
#header .closemenu {
  color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}
#header .pro-sidebar {
  width: 140%;
  min-width: 100%;
}
/* #header .pro-sidebar.collapsed {
    width: 150px;
    min-width: 80px;
  } */
#header .pro-sidebar-inner {
  background-color: #013b8e;
  border-radius: 1px;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 10px 20px;
  text-align: center;
  color: rgb(250, 246, 246);
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 10px;
}
#header .pro-sidebar-inner .pro-sidebar-layout .menu {
  color: rgb(253, 251, 251);
  /* margin: 40px 0; */
  padding: 10px;
  text-align: center;
  text-decoration: none;
  /* font-weight: bold; */
}

/* #header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #244f94 0%, #5242af 100%);
} */

#header .pro-sidebar-inner .pro-sidebar-layout .navlink {
  text-decoration: none;
}

/* #header .pro-sidebar-inner .pro-sidebar-layout .icon {
    color: rgb(253, 251, 251);
    margin: 10px 0px;
    text-align: center;
    font-weight: bold;
  } */
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  /* background-color: #1a191b; */
  color: rgb(252, 248, 248);
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
/* #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
  } */
#header .logo {
  padding: 20px;
}

.ant-menu-title-content {
  color:white;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark {
  background: #013b8e !important;
}
/* .ant-menu ant-menu-sub ant-menu-inline */
.ant-menu {
  background: #013b8e !important;
}
.main {
  background-color: hsl(183, 72%, 73%);
  width: 400px;
  height: 100%;
  padding: 10px;
  flex-direction: row;
  -ms-flex-align: center;
  margin: 7em auto;
  border-radius: 1em;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
  padding-top: 20px;
  text-align: center;
  color: #080708;
  font-weight: bold;
  font-size: 20px;
}

.ant-form-item-label{
  margin-left: 32px;
}

.ant-input un {
  margin-left: -29px;
    border-radius: 8px;
    margin-top: 5px;
    border-color: blueviolet;
   width: 100%; 
  color: rgb(38, 50, 56);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 5px;
  text-align: left;
}

form.form1 {
  padding-top: 40px;
}

.ant-input pass {
  margin-left: -29px;
  border-radius: 8px;
  margin-top: 5px;
  border-color: blueviolet;
  width: 100%; 
  color: rgb(38, 50, 56);
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  background: rgba(136, 126, 126, 0.04);
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  outline: none;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.02);
  margin-bottom: 5px;
  text-align: left;
}

.un:focus,
.pass:focus {
  border: 2px solid rgba(0, 0, 0, 0.18) !important;
}

.submit {
  cursor: pointer;
  text-align: center;
  color: #fff;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

a {
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
  color: #e1bee7;
  text-decoration: none;
}
.forgot{
  margin-left: 245px;
}
.ant-alert ant-alert-error ant-alert-banner{
  /* align-items: center; */
  width: 150px;
}

.errorMsg {
  color: red
}

@media (max-width: 600px) {
  .main {
    border-radius: 0px;
  }
}

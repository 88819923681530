 .card-style {
    box-shadow: 5px 2px 1px 2px rgba(151, 151, 151, 0.6);
  }
  .flexbox-container {
    display: flex;
    flex-direction: row;
    color:#527cb8;
}

.card-background{
  background-image: url(./common//bg-1.png);
    background-position-x: initial;
    background-position-y: initial;
    background-size: cover;
    /* background-repeat-x: initial
    background-repeat-y: initial; */
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-size: cover;
}

h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  color: #ffffff
}

h4 {
  color: #ffffff
}

.card-icon i {
  font-size: 30px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  color: #ffffff !important;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: inline-block;
}
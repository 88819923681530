.site-card-border-less-wrapper {
    padding: 30px;
  }

.col-md-8,
.col-md-6,
.col-md-4 {
padding: 30px;
background-color: #fff;
border: 6px solid #ececec;
text-align: justify;
}

.col-md-4 {
  text-align:center;
  }
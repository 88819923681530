.in-box{
    width: 80%;
    height: 50%;
    background-color: #ffffff;
    position: absolute;
    left: 65px;
    bottom: 85px;
    border-radius: 16px;
    box-shadow: 9px 9px 50px rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px dotted #1A0045;
}


.modalHeader{
    text-align: center;
    font-size: 30px !important;
    font-family: Poppins;
    letter-spacing: 0.08em;
    color: #1A0045;

}

.modalSubHeader{
    text-align: center;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 21px;
}

.uploadIcon{
    font-size: 125px;
    color: #1A0045;
}

.uploadedAlert{
    font-weight: bold;
    position: absolute;
    right: 11%;
    top: 32%;
    background-color: rgb(140, 211, 140);
    color: white;
    width: 500px;
    height: 210px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fileTypeError{
    font-weight: bold;
    position: absolute;
    padding-left: 20px;
    right: 11%;
    top: 5%;
    background-color: rgb(236, 142, 142);
    color: white;
    width: 500px;
    height: 110px;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

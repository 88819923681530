.ant-btn ant-btn-primary{
    margin-left:210px; 
}
.inputStyle {
    margin-left:50px; 
     width: 300px; 
}
  
.category_label{
    font-weight: 500;
}
.editButton{
    cursor: pointer;
}

.ant-form-item-control-input-content{
    margin-left: 30px;
}

.addfaq_btn{
    margin-left: 55px !important;
}
.errorMsg{
    color: red;
}
/* . css-1s2u09g-control{
    width: 257px;
    margin-left: -28px;
} */

@media (max-width: 600px) {
.main {
      border-radius: 0px;
 }
}

textarea {
    margin-left: -27px !important;
}

.btnicon{
  background-color: #686868; /* Blue background */
  border-radius: 20px;
  margin-left: -2px; 
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 8px; 
  width: 50px;
  font-size: 16px; /* Set a font size */
  cursor: pointer;
}
.btnicon:hover {
    background-color: RoyalBlue;
}